import { SerialModel } from '@/models/serial.model'
import { ProductModel } from '@/models/product.model'
import { ManufactureHeadModel } from '@/models/manufacture-head.model'

export class ManufactureSpecificationModel {
  constructor(request = {}) {
    this.delta = request?.delta
    this.document_head = new ManufactureHeadModel(request?.document_head)
    this.id = request?.id
    this.order = request?.order
    this.price = request?.price
    this.product = new ProductModel(request?.product)
    this.serial_count = request?.serial_count
    this.retailPrice = request?.retailPrice || 0
    this.serial = new SerialModel(request?.serial)
    this.sum = request?.sum || this.price * this.delta || 0
  }

  setSum = (val) => {
    this.sum = val
    this.price = Number((this.sum / this.delta).toFixed(2))
  }

  setPrice = (val) => {
    this.price = val
    this.sum = this.price * this.delta
  }

  setNds = (val) => {
    this.nds = val
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = this.price * this.delta
  }

  setRetailPrice = (val) => {
    this.retailPrice = val
  }

  setMarkup = (val) => {
    this.retailPrice = Number((this.price * (1 + val / 100)).toFixed(2))
  }

  get markup() {
    return Number((((this.retailPrice - this.price) / this.price) * 100).toFixed(4))
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      delta: +this.delta,
      price: +this.price,
      retailPrice: +this.retailPrice,
      sum: +this.sum
    }
  }
}
