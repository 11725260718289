var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "equipment-page"
  }, [_c('compound-modal', {
    attrs: {
      "compound": _vm.currentCompound,
      "disabled": ""
    }
  }), _c('repacking-head', {
    attrs: {
      "document": _vm.document,
      "loading": _vm.loading
    },
    on: {
      "onChange": _vm.onChange,
      "makeUsed": _vm.update_status
    }
  }), _c('div', {
    staticClass: "my-table"
  }, [_c('resizable-table', {
    ref: "equipment-table",
    attrs: {
      "default_fields": _vm.default_fields,
      "items": _vm.specs,
      "inside_card": false,
      "table_name": "repacking"
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.isAllSelected
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_order",
      fn: function (_ref) {
        var _item$parent;

        var item = _ref.item;
        return [_vm._v(" " + _vm._s((item.parent ? ((_item$parent = item.parent) === null || _item$parent === void 0 ? void 0 : _item$parent.order) + '.' : '') + (item.order || '')) + " ")];
      }
    }, {
      key: "body_id",
      fn: function (_ref2) {
        var _vm$selected;

        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item.id);
            }
          }
        })];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        var _data$item$parent, _data$item$parent2;

        return [data.value.id ? _c('div', {
          staticClass: "d-flex align-items-center",
          staticStyle: {
            "overflow": "hidden"
          }
        }, [_c('img', {
          staticClass: "mr-2",
          class: {
            'ml-4': !!((_data$item$parent = data.item.parent) !== null && _data$item$parent !== void 0 && _data$item$parent.id)
          },
          attrs: {
            "src": `/img/icons/repacking/${(_data$item$parent2 = data.item.parent) !== null && _data$item$parent2 !== void 0 && _data$item$parent2.id ? 'repacking.svg' : 'main_product.svg'}`
          }
        }), _c('product-spec-info', {
          attrs: {
            "product": data.item.product
          }
        })], 1) : _c('div', [_c('product-search', {
          attrs: {
            "document_type": "equipment",
            "document_head": _vm.document.id,
            "document": _vm.document
          },
          on: {
            "select_product": function (prd) {
              return _vm.select_product(prd, data.item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_delta",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'delta',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'delta'
          },
          on: {
            "input": function (val) {
              return _vm.setDelta(val, data.item);
            },
            "handle-input": function (val) {
              return _vm.setDelta(val, data.item, true);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_coeff",
      fn: function (data) {
        return [!!data.item.parent ? _c('table-row-input', {
          ref: data.item.id + 'coeff',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'coeff'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setCoeff(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setCoeff(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        }) : _c('div', [_vm._v("--")])];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'sum',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'sum'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setSum(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setSum(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_markup",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'markup',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'markup'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setMarkup(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setMarkup(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_price",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'price',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'price'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_retailPrice",
      fn: function (data) {
        return [_c('table-row-input', {
          ref: data.item.id + 'retailPrice',
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "input_id": data.item.id + 'retailPrice'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setRetailPrice(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setRetailPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_currentPrice",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.product.retailPrice || '–') + " ")];
      }
    }, {
      key: "body_measurement",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.product.measurement.name || '–') + " ")];
      }
    }, {
      key: "bottom",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  })], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "documents",
      "doc_type": "manufacture",
      "print": false,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "remove_items": function ($event) {
        return _vm.remove_items(_vm.selected);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }