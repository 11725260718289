<template>
  <div class="equipment-page">
    <compound-modal
      :compound="currentCompound"
      disabled
    />
    <!-- <breadcrumbs :extra="document" /> -->
    <repacking-head
      :document="document"
      :loading="loading"
      @onChange="onChange"
      @makeUsed="update_status"
    />
    <div class="my-table">
      <resizable-table
        ref="equipment-table"
        :default_fields="default_fields"
        :items="specs"
        :inside_card="false"
        table_name="repacking"
      >
        <template #head_id>
          <e-checkbox
            :checked="isAllSelected"
            @click="changeSelectAll"
          />
        </template>
        <template #body_order="{ item }">
          {{ (item.parent ? item.parent?.order + '.' : '') + (item.order || '') }}
        </template>
        <template #body_id="{ item }">
          <b-form-checkbox
            :checked="selected?.some((el) => el === item?.id)"
            position="center"
            @change="(val) => addSelected(val, item.id)"
          />
        </template>
        <template #body_product="data">
          <div
            v-if="data.value.id"
            class="d-flex align-items-center"
            style="overflow: hidden"
          >
            <img
              class="mr-2"
              :class="{ 'ml-4': !!data.item.parent?.id }"
              :src="`/img/icons/repacking/${data.item.parent?.id ? 'repacking.svg' : 'main_product.svg'}`"
            />
            <product-spec-info :product="data.item.product" />
          </div>
          <div v-else>
            <product-search
              document_type="equipment"
              :document_head="document.id"
              :document="document"
              @select_product="(prd) => select_product(prd, data.item)"
            />
          </div>
        </template>
        <template #body_delta="data">
          <table-row-input
            :ref="data.item.id + 'delta'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'delta'"
            @input="(val) => setDelta(val, data.item)"
            @handle-input="(val) => setDelta(val, data.item, true)"
            @on-focus="centeredScroll"
          />
        </template>
        <template #body_coeff="data">
          <table-row-input
            v-if="!!data.item.parent"
            :ref="data.item.id + 'coeff'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'coeff'"
            @handle-input="(val) => setCoeff(val, data.item, true)"
            @input="(val) => setCoeff(val, data.item)"
            @on-focus="centeredScroll"
          />
          <div v-else>--</div>
        </template>
        <template #body_sum="data">
          <table-row-input
            :ref="data.item.id + 'sum'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'sum'"
            @handle-input="(val) => setSum(val, data.item, true)"
            @input="(val) => setSum(val, data.item)"
            @on-focus="centeredScroll"
          />
        </template>
        <template #body_markup="data">
          <table-row-input
            :ref="data.item.id + 'markup'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'markup'"
            @handle-input="(val) => setMarkup(val, data.item, true)"
            @input="(val) => setMarkup(val, data.item)"
            @on-focus="centeredScroll"
          />
        </template>
        <template #body_price="data">
          <table-row-input
            :ref="data.item.id + 'price'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'price'"
            @handle-input="(val) => setPrice(val, data.item, true)"
            @input="(val) => setPrice(val, data.item)"
            @on-focus="centeredScroll"
          />
        </template>
        <template #body_retailPrice="data">
          <table-row-input
            :ref="data.item.id + 'retailPrice'"
            :value="data.value"
            :fixed="document.fixed"
            :input_id="data.item.id + 'retailPrice'"
            @handle-input="(val) => setRetailPrice(val, data.item, true)"
            @input="(val) => setRetailPrice(val, data.item)"
            @on-focus="centeredScroll"
          />
        </template>
        <template #body_currentPrice="data">
          {{ data.item.product.retailPrice || '–' }}
        </template>
        <template #body_measurement="data">
          {{ data.item.product.measurement.name || '–' }}
        </template>
        <template #bottom></template>
      </resizable-table>
    </div>

    <table-navbar
      :items="selected"
      items_name="documents"
      doc_type="manufacture"
      :print="false"
      :clear_selected="clearSelected"
      @remove_items="remove_items(selected)"
    />
  </div>
</template>
<script>
  import ResizableTable from '@/components/ResizableTable'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { ProductModel } from '@/models/product.model'
  import { CompoundModel } from '@/models/compound.model'
  import CompoundModal from '@/views/manufacture/components/CompoundModal'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch.vue'
  import { mapActions } from 'vuex'
  import { RepackingSpecModel } from '@/models/repacking-spec.model'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import { RepackingHeadModel } from '@/models/repacking-head.model'
  import RepackingHead from '@/views/manufacture/components/repacking/RepackingHead.vue'

  export default {
    name: 'Repacking',
    components: {
      RepackingHead,
      ProductSpecInfo,
      ResizableTable,
      TableRowInput,
      CompoundModal,
      TableNavbar,
      ProductSearch
    },
    apollo: {
      ReverseManufactureHead: {
        query: require('../../gql/ReverseManufactureHead.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
          }
        },
        result({ data }) {
          this.document = new RepackingHeadModel(data.ReverseManufactureHead)
          this.setBreadcrumbs({ ...data.ReverseManufactureHead, is_go_back: true })
          if (this.document.fixed) {
            this.specs = this.specs.filter((el) => el.id)
          }
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        }
      },
      ReverseManufactureSpecList: {
        query: require('../../gql/ReverseManufactureSpecList.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
              pagination: { skip: 0, take: 100 }
            }
          }
        },
        result({ data }) {
          this.specs = data.ReverseManufactureSpecList?.list?.map((el) => new RepackingSpecModel(el)) || []
          this.setProductSearch()
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        }
      }
    },
    data() {
      return {
        timer: null,
        loading: false,
        currentCompound: new CompoundModel(),
        title: this.$route.meta.title,
        document: new RepackingHeadModel(),
        specs: [],
        default_fields: [
          {
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 54
          },
          {
            key: 'product',
            label: 'Наименование товара',
            thStyle: 'width: 250px ;min-width: 250px;',
            checked: true,
            width: 250
          },
          {
            key: 'delta',
            label: 'Общее количество',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 120
          },
          {
            key: 'coeff',
            label: 'Коэф.',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 120
          },
          {
            key: 'measurement',
            label: 'Единица измерения',
            thStyle: 'min-width: 54px;width: 54px',
            checked: true,
            width: 120,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 140,
            formatter: (value, key, item) => item.product?.retailPrice ?? 0
          },
          {
            key: 'remainders',
            label: 'Остаток',
            formatter: (value, key, item) => this.getRemainder(item.product.remainders),
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          }
        ],
        isAllSelected: false,
        selected: []
      }
    },

    watch: {
      'specs'(newItems) {
        const currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.specs.length === 0) {
          this.isAllSelected = false
        } else if (currentSelectedAfterPagination.length === this.specs.length) {
          this.isAllSelected = true
        } else {
          this.isAllSelected = false
        }
      }
    },

    mounted() {},

    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      async remove_items(items) {
        await this.$apollo.mutate({
          mutation: require('../../gql/DeleteReverseManufactureSpec.graphql'),
          variables: {
            input: {
              document_head: this.document?.id,
              ids: items
            }
          }
        })
        this.$apollo.queries.ReverseManufactureSpecList.refresh()
        this.selected = []
      },

      changeSelectAll() {
        this.isAllSelected = !this.isAllSelected
        if (!this.isAllSelected) {
          this.specs.forEach(
            (obj) => (this.selected = this.selected.filter((item) => obj.id !== item))
          )
          return
        }

        let arrayCurrenSelected = []
        this.specs.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.specs.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.specs.length) this.isAllSelected = false
        if (currentSeleted.length === this.specs.length) this.isAllSelected = true
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      setProductSearch() {
        if (this.document.fixed) return
        const spec = this.specs.find((el) => el.parent?.id == null)
        const last_spec = this.specs[this.specs.length - 1]
        this.specs.push(new RepackingSpecModel({ parent: spec, order: last_spec?.order + 1 || 1 }))
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }
        return 0
      },
      setDelta(val, item, isEnter) {
        item.setDelta(val)
        if (item.parent?.id) {
          item.setCoeff(+(val / item.parent?.delta).toFixed(2))
          const index = this.specs.findIndex((el) => el.id === item.id)
          this.specs[index] = item
        }
        if (isEnter) this.$nextTick(() => document.getElementById(item.id + 'coeff')?.click())

        this.updateSpec(item)
      },
      setCoeff(val, item, isEnter) {
        if (val !== item.coeff) {
          item.setCoeff(val)
          if (item.parent?.id) {
            item.setDelta(+(item.parent?.delta * val).toFixed(2))
            const index = this.specs.findIndex((el) => el.id === item.id)
            this.specs[index] = item
          }

          this.updateSpec(item)
        }

        if (isEnter) document.getElementById(item.id + 'price')?.click()
      },

      async onChange() {
        await this.$apollo.mutate({
          mutation: require('../../gql/UpdateReverseManufactureHead.graphql'),
          variables: {
            input: this.document.input
          }
        })
      },

      setPrice(val, item, isEnter) {
        item.setPrice(val)
        if (isEnter) document.getElementById(item.id + 'sum')?.click()
        this.updateSpec(item)
      },

      setSum(val, item) {
        item.setSum(val)
        this.updateSpec(item)
      },

      setMarkup(val, item) {
        item.setMarkup(val)
        document.getElementById(item.id + 'retailPrice')?.click()
        this.updateSpec(item)
      },

      setRetailPrice(val, item) {
        item.setRetailPrice(val)
        setTimeout(() => document.getElementById('productSearchInput').focus(), 700)
        this.updateSpec(item)
      },
      async updateSpec(spec) {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require(`../../gql/CreateReverseManufactureSpec.graphql`),
            variables: {
              specs: [spec.input],
              head: this.document.id
            }
          })
          if (!spec.parent?.id) {
            this.$apollo.queries.ReverseManufactureSpecList.refetch()
          }
        }, 200)
      },
      async select_product(item, spec) {
        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0

        spec.product = new ProductModel(item)
        spec.delta = item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount
        spec.document_head = this.document

        spec.setDelta(delta)
        const cost_price =
          spec.product.current_compound?.raw_material?.reduce((sum, el) => {
            return sum + (el.product?.purchasePrice || 0) * el.gross
          }, 0) / spec.product.current_compound?.value
        if (spec.product.purchasePrice || cost_price) {
          spec.setPrice(cost_price || spec.product.purchasePrice || 0)
          if (spec.product.markup) spec.setMarkup(+spec.product.markup)
          else if (!spec.product.markup && spec.product.retailPrice) {
            spec.setRetailPrice(spec.product.retailPrice)
          }
        }

        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/CreateReverseManufactureSpec.graphql'),
          variables: {
            head: spec.document_head.id,
            specs: [spec.input]
          }
        })
        spec.id = data.CreateReverseManufactureSpec.list[0].id
        await this.$apollo.queries.ReverseManufactureHead.refresh()
        await this.$apollo.queries.ReverseManufactureSpecList.refresh()
        this.setProductSearch()
        setTimeout(() => document.getElementById(data.CreateReverseManufactureSpec.list[0].id + 'delta')?.click(), 300)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      async update_status() {
        try {
          this.loading = true
          await this.$apollo.mutate({
            mutation: require('../../gql/ChangeReverseManufactureHeadStatus.graphql'),
            variables: {
              id: this.document.id
            }
          })
          await this.$apollo.queries.ReverseManufactureHead.refetch()
          this.$apollo.queries.ReverseManufactureSpecList.refetch()
        } catch (e) {
          this.loading = false
        } finally {
          this.loading = false
        }
        this.loading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .my-table {
    flex: 1;
  }
  :deep(.my-table) {
    #resizeable-table {
      background-color: white !important;
    }
    .wrapper__inner {
      overflow: visible;
    }

    .p-0 {
      display: flex !important;
      flex-direction: column !important;
      flex: 1 !important;
    }
    .b-search {
      &-result {
        height: 400px;
      }
    }

    .table-docs.b-table-sticky-header {
      max-height: none;
    }
  }

  .b-search {
    &__wrapper {
      & > div {
        position: absolute;
      }
    }

    &-result {
      .item {
        &:hover {
          background: #efefef;
        }
      }
    }
  }

  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }

  .equipment-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .calc-icon:hover {
    cursor: pointer;
    background: #e2e2e2;
  }

  .calc-icon {
    padding: 6px;
    border-radius: 4px;
  }
</style>
