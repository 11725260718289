var render = function render(){
  var _vm$compound, _vm$compound2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    staticClass: "compound-modal",
    attrs: {
      "id": "compound-modal",
      "centered": "",
      "size": "xl",
      "title": "Новая калькуляция"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [!_vm.disabled ? _c('div', {
          staticClass: "d-flex"
        }, [_vm.compound.status === 'draft' ? _c('b-button', {
          staticClass: "footer-button-ok",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.accept
          }
        }, [_vm._v(" Провести ")]) : _vm._e(), _vm.compound.status === 'draft' ? _c('b-button', {
          staticClass: "footer-button-not",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.back
          }
        }, [_vm._v(" Сохранить как черновик ")]) : _vm._e(), _vm.compound.status === 'active' ? _c('b-button', {
          staticClass: "footer-button-not",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.archive
          }
        }, [_vm._v(" Архивировать ")]) : _vm._e()], 1) : _c('div')];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex align-items-end"
  }, [_c('div', {
    staticClass: "modal-input"
  }, [_c('label', [_vm._v("Наименование")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.disabled,
      "value": (_vm$compound = _vm.compound) === null || _vm$compound === void 0 ? void 0 : _vm$compound.name
    },
    on: {
      "input": _vm.setCompoundName
    }
  })], 1), _c('div', {
    staticClass: "modal-input"
  }, [_c('label', [_vm._v("Дата начала")]), _c('div', [_c('date-picker', {
    staticClass: "elm-calendar datepicker-range",
    attrs: {
      "disabled": _vm.disabled,
      "type": "date",
      "placeholder": "Дата начала",
      "value": (_vm$compound2 = _vm.compound) === null || _vm$compound2 === void 0 ? void 0 : _vm$compound2.date
    },
    on: {
      "on-change": _vm.setCompoundDate
    }
  })], 1)]), _c('div', {
    staticClass: "modal-input"
  }, [_c('label', [_vm._v("Норма закладки")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "disabled": _vm.disabled,
      "formatter": _vm.format,
      "value": _vm.compound.value
    },
    on: {
      "input": _vm.setCompoundValue
    }
  })], 1), _c('div', {
    staticClass: "modal-input"
  }, [_c('b-form-checkbox', {
    staticStyle: {
      "line-height": "25px"
    },
    attrs: {
      "checked": _vm.compound.write_on_outgoing,
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.setWriteOnOutgoing
    }
  }, [_vm._v(" Списывать автоматически ")])], 1)]), _c('b-tabs', {
    staticClass: "modal-tabs",
    attrs: {
      "active-nav-item-class": "text-dark font-weight-bold"
    }
  }, [_c('b-tab', {
    staticClass: "table-raw-materials",
    attrs: {
      "title": "Сырьё"
    }
  }, [_c('h5', {
    staticStyle: {
      "margin-top": "22px"
    }
  }, [_vm._v("Расчетная себестоимость: " + _vm._s(_vm.cost_price.toFixed(2)) + " ₽")]), _c('div', {
    staticClass: "modal-table-wrapper"
  }, [_c('div', {
    staticClass: "modal-table-wrapper_box_content"
  }, [_c('b-table', {
    staticClass: "modal-b-table-content",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.compound.raw_material
    },
    scopedSlots: _vm._u([{
      key: "head(id)",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('img', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": "/img/icons/trash.svg",
            "alt": "удалить"
          },
          on: {
            "click": function ($event) {
              return _vm.removeCompoundProduct(data.value);
            }
          }
        })];
      }
    }, {
      key: "cell(product)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(data.value.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [_vm._v(" " + _vm._s(data.value.articul || 'Не указан') + " ")])])];
      }
    }, {
      key: "cell(gross)",
      fn: function (data) {
        return [_c('table-row-input', {
          attrs: {
            "value": data.value,
            "fixed": _vm.disabled,
            "input_id": data.item.id + 'gross'
          },
          on: {
            "handle-input": function (value) {
              return _vm.setGross(value, data.item);
            },
            "input": function (value) {
              return _vm.inputGross(value, data.item);
            }
          }
        })];
      }
    }, {
      key: "cell(net)",
      fn: function (data) {
        return [_c('table-row-input', {
          attrs: {
            "value": data.value,
            "fixed": _vm.disabled,
            "input_id": data.item.id + 'net'
          },
          on: {
            "handle-input": function (value) {
              return _vm.setNet(value, data.item);
            },
            "input": function (value) {
              return _vm.setNet(value, data.item);
            }
          }
        })];
      }
    }, {
      key: "cell(net_per)",
      fn: function (data) {
        return [_c('table-row-input', {
          attrs: {
            "value": data.value,
            "fixed": _vm.disabled,
            "input_id": data.item.id + 'net_per'
          },
          on: {
            "handle-input": function (value) {
              return _vm.setNetPer(value, data.item, true);
            },
            "input": function (value) {
              return _vm.setNetPer(value, data.item);
            }
          }
        })];
      }
    }, {
      key: "cell(measurement)",
      fn: function (data) {
        var _data$item$product$me;

        return [_vm._v(" " + _vm._s(((_data$item$product$me = data.item.product.measurement) === null || _data$item$product$me === void 0 ? void 0 : _data$item$product$me.name) || '–') + " ")];
      }
    }, {
      key: "cell(price)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(data.item.product.purchasePrice)) + " ")];
      }
    }, {
      key: "cell(sum)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(data.item.product.purchasePrice * data.item.gross)) + " ")];
      }
    }])
  })], 1), _c('div', {
    staticClass: "d-flex footer",
    staticStyle: {
      "padding-top": "8px"
    }
  }, [!_vm.disabled ? _c('product-search-all', {
    attrs: {
      "placeholder": "Добавить сырье"
    },
    on: {
      "select_product": _vm.addCompoundProduct
    }
  }) : _vm._e()], 1)])]), _c('b-tab', {
    attrs: {
      "title": "Технология приготовления"
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "margin-top": "22px",
      "width": "70%"
    },
    attrs: {
      "value": _vm.compound.cooking_technology,
      "disabled": _vm.disabled,
      "rows": "10"
    },
    on: {
      "input": _vm.setCookingTechnology
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Технология хранения"
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "margin-top": "22px",
      "width": "70%"
    },
    attrs: {
      "value": _vm.compound.storage_technology,
      "disabled": _vm.disabled,
      "rows": "10"
    },
    on: {
      "input": _vm.setStorageTechnology
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }