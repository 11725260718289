<template>
  <div class="b-search-product">
    <div class="b-search__wrapper">
      <b-form-input
        id="productSearchInput"
        ref="searchInput"
        v-model="searchString"
        type="text"
        :placeholder="placeholder"
        :is-keyup="true"
        autocomplete="off"
        @keyup="search_product"
      />
      <template v-if="search_result">
        <apollo-query
          :query="require('../gql/searchProduct.gql')"
          :variables="{
            name: searchString,
            branch: currentBranch.id,
            document_head: document_head,
            document_type: document_type
          }"
          :skip="searchString.length < 1"
          :update="searchProductResult"
          fetch-policy="no-cache"
        >
          <template #default="{ result: { error, data }, isLoading }">
            <div
              v-if="isLoading"
              class="b-search-result p-2 center"
            >
              <b-spinner
                variant="primary"
                class="mt-4"
              />
            </div>
            <div
              v-else-if="error"
              class="b-search-result center error apollo"
            >
              Ошибка при запросе
            </div>
            <div
              v-else-if="data"
              class="b-search-result text-left"
            >
              <template v-if="data.ProductSearch?.total > 0">
                <div
                  v-for="(item, index) in data.ProductSearch.products"
                  :key="index"
                  v-click-outside="clickOutside"
                  class="no-control item cursor"
                  @click="select_product(item)"
                >
                  <div class="no-control item-name">
                    {{ item.name }}
                  </div>
                  <div class="no-control item-info">
                    <span class="mr-2 no-control">
                      {{ item.articul ? item.articul : '-----' }}
                    </span>
                    <span class="no-control mr-2">
                      {{ item.supplier?.name ? item.supplier?.name : 'Нет данных' }}
                    </span>
                    <span
                      class="no-control"
                      style="float: right; margin-right: 0"
                    >
                      {{ item.retailPrice ? item.retailPrice : `0.00` }}
                      ₽
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="center">Не найдено</div>
              </template>
            </div>
            <div
              v-else
              class="b-search-result p-3 center"
            >
              <b-spinner variant="primary" />
            </div>
          </template>
        </apollo-query>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProductSearch',
    props: {
      document_head: String,
      document_type: {
        type: String,
        default: () => 'incoming'
      },
      placeholder: {
        type: String,
        default: () => 'Добавить новый товар'
      }
    },
    data() {
      return {
        searchString: '',
        search_result: false,
        isModalOpened: false
      }
    },
    mounted() {
      this.listenerKeyUp()
    },
    methods: {
      search_product() {
        if (this.searchString.length > 1) return (this.search_result = true)

        this.search_result = false
      },
      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },
      select_product(item) {
        this.$emit('select_product', item)
        this.searchString = ''
      },
      clickOutside() {
        this.searchString = ''
        this.search_result = false
      },
      listenerKeyUp() {
        this.$root.$on('bv::modal::show', () => {
          this.isModalOpened = true
        })
        this.$root.$on('bv::modal::hide', () => {
          this.isModalOpened = false
        })
        if (this.isModalOpened) return
        const regexpRu = /^[?!,.а-яА-ЯёЁ0-9]+$/
        const regexpEn = /^[?!,.a-zA_Z0-9]+$/
        let interval = null

        window.addEventListener('keydown', (item) => {
          if (item.target.localName === 'input') return
          const key = item.key
          if (interval) clearInterval(interval)
          if (this.time === 0) interval = setInterval(this.counterSeconds, 100)

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') return (this.searchString = '')

          if (key === 'Backspace')
            return (this.searchString = this.searchString.substring(0, this.searchString.length - 1))

          if (key === 'Enter') {
            if (this.time < 1) {
              this.search_by_scanner = true
            }
            this.search_product()

            this.resetTimer()
            clearInterval(interval)
          }

          if (regexpRu.test(key) || regexpEn.test(key))
            if (typeof item.key === 'string') return (this.searchString = this.searchString + key)

          // this.handler_search_str = this.handler_search_str + key
        })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .b-search-product {
    &__wrapper {
      position: relative;
      width: 100%;
      z-index: 15;

      & > div {
        position: absolute;
      }
    }

    .b-search-result {
      position: absolute;
      bottom: 10%;
      width: 330px;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 10px 0;
      max-height: 350px;
      overflow-y: auto;
      z-index: 10000;

      .item {
        padding: 8px 14px;

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #313131;
        }

        &-info {
          font-size: 12px;
          line-height: 24px;
          color: #888888;
          height: 24px;
          overflow: hidden;

          span {
            margin-right: 12px;
          }
        }

        &:hover {
          background: #efefef;
        }
      }
    }
    .error {
      padding-left: 5px;
    }
  }
</style>
