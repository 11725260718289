import { SerialModel } from '@/models/serial.model'
import { ProductModel } from '@/models/product.model'
import { ManufactureHeadModel } from '@/models/manufacture-head.model'

export class RepackingSpecModel {
  constructor(request = {}) {
    this.delta = request?.delta
    this.document_head = new ManufactureHeadModel(request?.document_head)
    this.id = request?.id
    this.order = request?.order
    this.coeff = request?.coeff
    this.price = request?.price
    this.product = new ProductModel(request?.product)
    this.serial_count = request?.serial_count
    this.retail_price = request?.retail_price || 0
    this.serial = new SerialModel(request?.serial)
    this.sum = request?.sum || this.price * this.delta || 0
    this.parent = request?.parent ? new RepackingSpecModel(request?.parent) : null
  }

  setSum = (val) => {
    this.sum = val
    this.price = Number((this.sum / this.delta).toFixed(2))
  }

  setPrice = (val) => {
    this.price = val
    this.sum = this.price * this.delta
  }

  setNds = (val) => {
    this.nds = val
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = this.price * this.delta
  }

  setRetailPrice = (val) => {
    this.retail_price = val
  }
  setCoeff = (val) => {
    this.coeff = val
  }

  setMarkup = (val) => {
    this.retail_price = Number((this.price * (1 + val / 100)).toFixed(2))
  }

  get markup() {
    return Number((((this.retail_price - this.price) / this.price) * 100).toFixed(4))
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      parent: this.parent?.id,
      delta: +this.delta,
      price: +this.price,
      coeff: +this.coeff,
      retail_price: +this.retail_price,
      sum: +this.sum
    }
  }
}
