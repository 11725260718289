<template>
  <div>
    <b-modal
      id="compound-modal"
      centered
      size="xl"
      title="Новая калькуляция"
      class="compound-modal"
    >
      <div class="d-flex align-items-end">
        <div class="modal-input">
          <label>Наименование</label>
          <b-form-input
            :disabled="disabled"
            :value="compound?.name"
            @input="setCompoundName"
          />
        </div>
        <div class="modal-input">
          <label>Дата начала</label>
          <div>
            <date-picker
              :disabled="disabled"
              type="date"
              class="elm-calendar datepicker-range"
              placeholder="Дата начала"
              :value="compound?.date"
              @on-change="setCompoundDate"
            />
          </div>
        </div>
        <div class="modal-input">
          <label>Норма закладки</label>
          <b-form-input
            type="number"
            :disabled="disabled"
            :formatter="format"
            :value="compound.value"
            @input="setCompoundValue"
          />
        </div>
        <div class="modal-input">
          <b-form-checkbox
            :checked="compound.write_on_outgoing"
            :disabled="disabled"
            style="line-height: 25px"
            @change="setWriteOnOutgoing"
          >
            Списывать автоматически
          </b-form-checkbox>
        </div>
      </div>
      <b-tabs
        active-nav-item-class="text-dark font-weight-bold"
        class="modal-tabs"
      >
        <b-tab
          title="Сырьё"
          class="table-raw-materials"
          
        >
          <h5 style="margin-top: 22px">Расчетная себестоимость: {{ cost_price.toFixed(2) }} ₽</h5>
          <div class="modal-table-wrapper">
            <div class="modal-table-wrapper_box_content">
              <b-table
                class="modal-b-table-content"
                :fields="fields"
                :items="compound.raw_material"
              >
                <template #head(id)><span></span></template>
                <template #cell(id)="data">
                  <img
                    style="cursor: pointer"
                    src="/img/icons/trash.svg"
                    alt="удалить"
                    @click="removeCompoundProduct(data.value)"
                  />
                </template>
                <template #cell(product)="data">
                  <div class="text-left">
                    {{ data.value.name }}
                    <div class="b-name article">
                      {{ data.value.articul || 'Не указан' }}
                    </div>
                  </div>
                </template>
                <template #cell(gross)="data">
                  <table-row-input
                    :value="data.value"
                    :fixed="disabled"
                    :input_id="data.item.id + 'gross'"
                    @handle-input="(value) => setGross(value, data.item)"
                    @input="(value) => inputGross(value, data.item)"
                  />
                </template>
                <template #cell(net)="data">
                  <table-row-input
                    :value="data.value"
                    :fixed="disabled"
                    :input_id="data.item.id + 'net'"
                    @handle-input="(value) => setNet(value, data.item)"
                    @input="(value) => setNet(value, data.item)"
                  />
                </template>
                <template #cell(net_per)="data">
                  <table-row-input
                    :value="data.value"
                    :fixed="disabled"
                    :input_id="data.item.id + 'net_per'"
                    @handle-input="(value) => setNetPer(value, data.item, true)"
                    @input="(value) => setNetPer(value, data.item)"
                  />
                </template>
                <template #cell(measurement)="data">
                  {{ data.item.product.measurement?.name || '–' }}
                </template>
                <template #cell(price)="data">
                  {{ formatPrice(data.item.product.purchasePrice) }}
                </template>
                <template #cell(sum)="data">
                  {{ formatPrice(data.item.product.purchasePrice * data.item.gross) }}
                </template>
              </b-table>
            </div>
            <div class="d-flex footer" style="padding-top: 8px;">
              <product-search-all
                v-if="!disabled"
                placeholder="Добавить сырье"
                @select_product="addCompoundProduct"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Технология приготовления">
          <b-form-textarea
            :value="compound.cooking_technology"
            style="margin-top: 22px; width: 70%"
            :disabled="disabled"
            rows="10"
            @input="setCookingTechnology"
          />
        </b-tab>
        <b-tab title="Технология хранения">
          <b-form-textarea
            :value="compound.storage_technology"
            style="margin-top: 22px; width: 70%"
            :disabled="disabled"
            rows="10"
            @input="setStorageTechnology"
          />
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <div
          v-if="!disabled"
          class="d-flex"
        >
          <b-button
            v-if="compound.status === 'draft'"
            class="footer-button-ok"
            variant="primary"
            @click="accept"
          >
            Провести
          </b-button>
          <b-button
            v-if="compound.status === 'draft'"
            class="footer-button-not"
            variant="outline-primary"
            @click="back"
          >
            Сохранить как черновик
          </b-button>
          <b-button
            v-if="compound.status === 'active'"
            class="footer-button-not"
            variant="primary"
            @click="archive"
          >
            Архивировать
          </b-button>
        </div>
        <div v-else></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ProductSearchAll from '../../manufacture/components/ProductSearchAll'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { formatMoney } from '@/utils/formatMoney'
  import { CompoundModel } from '@/models/compound.model'
  export default {
    name: 'CompoundModal',
    components: {
      ProductSearchAll,
      TableRowInput
    },
    props: {
      compound: { type: CompoundModel, default: () => new CompoundModel() },
      disabled: Boolean
    },
    data() {
      return {
        show: true,
        fields: [
          { key: 'id', thStyle: 'width:50px' },
          { key: 'product', label: 'Наименование' },
          { key: 'gross', label: 'брутто', thStyle: 'width:150px' },
          { key: 'net_per', label: '% нетто', thStyle: 'width:150px' },
          { key: 'net', label: 'нетто', thStyle: 'width:150px' },
          { key: 'measurement', label: 'Ед. изм.', thStyle: 'width:75px' },
          { key: 'price', label: 'Цена' },
          { key: 'sum', label: 'Сумма' }
        ],
        timer: null
      }
    },
    computed: {
      cost_price() {
        return (
          this.compound?.raw_material?.reduce((sum, el) => {
            return sum + (el.product?.purchasePrice || 0) * el.gross
          }, 0) || 0
        )
      }
    },
    methods: {
      format(e) {
        return String(e).substring(0, 6)
      },
      updateCompound() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.saveCompound()
        }, 500)
      },
      async saveCompound() {
        return this.$apollo.mutate({
          mutation: require(`../gql/UpdateCompound.graphql`),
          variables: {
            input: this.compound.input
          }
        })
      },
      setCompoundName(value) {
        this.compound.setName(value)
        this.updateCompound()
      },
      setCompoundDate(value) {
        this.compound.setDate(value)
        this.updateCompound()
      },
      setCompoundValue(value) {
        this.compound.setValue(value)
        this.updateCompound()
      },
      setWriteOnOutgoing(value) {
        this.compound.setWriteOnOutgoing(value)
        this.updateCompound()
      },
      setCookingTechnology(value) {
        this.compound.setCookingTechnology(value)
        this.updateCompound()
      },
      setStorageTechnology(value) {
        this.compound.setStorageTechnology(value)
        this.updateCompound()
      },
      async accept() {
        this.compound.setStatus('active')
        await this.saveCompound()
        this.$emit('refresh_table')
        this.$bvModal.hide('compound-modal')
      },
      async archive() {
        this.compound.setStatus('archive')
        await this.saveCompound()
        this.$emit('refresh_table')
        this.$bvModal.hide('compound-modal')
      },
      inputGross(val, item) {
        item.setGross(val)
        this.updateSpec(item)
      },
      setGross(val, item) {
        item.setGross(val)
        document.getElementById(item.id + 'net_per')?.click()
        this.updateSpec(item)
      },
      setNet(val, item) {
        item.setNet(val)
        this.updateSpec(item)
      },
      setNetPer(val, item, isEnter) {
        item.setNetPer(val)
        this.updateSpec(item)
        if (isEnter) {
          document.getElementById(item.id + 'net')?.click()
        }
      },
      async updateSpec(item) {
        await this.$apollo.mutate({
          mutation: require(`../gql/UpdateCompoundProduct.graphql`),
          variables: {
            input: item.input
          }
        })
      },
      formatPrice(val) {
        return formatMoney(val)
      },
      async addCompoundProduct(item) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateCompoundProduct.graphql'),
          variables: {
            input: {
              compound: this.compound.id,
              raw_material: [
                {
                  product: item.id
                }
              ]
            }
          }
        })
        this.$emit('refresh_compound', () =>
          document.getElementById(data?.CreateCompoundProduct[0].id + 'gross').click()
        )
      },
      removeCompoundProduct(item) {
        this.$apollo.mutate({
          mutation: require('../gql/RemoveCompoundProduct.graphql'),
          variables: {
            id: item
          }
        })
        this.$emit('refresh_compound')
      },
      async back() {
        await this.saveCompound()
        this.$emit('refresh_table')
        this.$bvModal.hide('compound-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-raw-materials {
    height: 87%;
  }
  .modal-input {
    margin-right: 16px;
  }
  ::v-deep .tab-content {
    height: 88%;
  }
  ::v-deep #compound-modal .modal-body {
    overflow-y: visible;
  }

  .modal-tabs {
    margin-top: 32px;
    height: 88%;
  }
  .modal-table-wrapper {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 93%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .modal-table-wrapper_box_content {
      height: 90%;
      overflow-y: auto;
      position: relative;
    }

    .modal-b-table-content {
      position: absolute;
    }

    .footer {
      border-top: 1px solid #e1e1e1;
      padding: 8px 0px 8px 60px;
    }
  }

  ::v-deep() {
    #compound-modal {
      th {
        border-top: none !important;
      }
      table {
        min-height: 100px;
        border: none !important;
      }
      .modal-dialog,
      .modal-content {
        /* 80% of window height */
        height: 80%;
      }

      .modal-body {
        /* 100% = dialog height, 120px = header + footer */
        // max-height: calc(100% - 120px);
        height: 100%;

        // overflow-y: scroll;
      }

      .custom-control-label {
        line-height: 25px;
      }
    }
  }

  .b-name {
    &.article {
      color: #888888;
    }
  }
  .b-name.article {
    line-height: 17px;
    margin-top: 3px;
  }
  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }
  .footer-button-ok {
    margin-right: 5px;
    font-size: 14px;
    width: 88px;
  }
  .footer-button-not {
    margin-right: 5px;
    font-size: 14px;
    width: 180px;
    white-space: nowrap;
  }
</style>
