var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-search-product"
  }, [_c('div', {
    staticClass: "b-search__wrapper"
  }, [_c('b-form-input', {
    ref: "searchInput",
    attrs: {
      "id": "productSearchInput",
      "type": "text",
      "placeholder": _vm.placeholder,
      "is-keyup": true,
      "autocomplete": "off"
    },
    on: {
      "keyup": _vm.search_product
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _vm.search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../gql/searchProduct.gql"),
      "variables": {
        name: _vm.searchString,
        branch: _vm.currentBranch.id,
        document_head: _vm.document_head,
        document_type: _vm.document_type
      },
      "skip": _vm.searchString.length < 1,
      "update": _vm.searchProductResult,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _data$ProductSearch;

        var _ref$result = _ref.result,
            error = _ref$result.error,
            data = _ref$result.data,
            isLoading = _ref.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          staticClass: "mt-4",
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "b-search-result center error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result text-left"
        }, [((_data$ProductSearch = data.ProductSearch) === null || _data$ProductSearch === void 0 ? void 0 : _data$ProductSearch.total) > 0 ? _vm._l(data.ProductSearch.products, function (item, index) {
          var _item$supplier, _item$supplier2;

          return _c('div', {
            directives: [{
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickOutside,
              expression: "clickOutside"
            }],
            key: index,
            staticClass: "no-control item cursor",
            on: {
              "click": function ($event) {
                return _vm.select_product(item);
              }
            }
          }, [_c('div', {
            staticClass: "no-control item-name"
          }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
            staticClass: "no-control item-info"
          }, [_c('span', {
            staticClass: "mr-2 no-control"
          }, [_vm._v(" " + _vm._s(item.articul ? item.articul : '-----') + " ")]), _c('span', {
            staticClass: "no-control mr-2"
          }, [_vm._v(" " + _vm._s((_item$supplier = item.supplier) !== null && _item$supplier !== void 0 && _item$supplier.name ? (_item$supplier2 = item.supplier) === null || _item$supplier2 === void 0 ? void 0 : _item$supplier2.name : 'Нет данных') + " ")]), _c('span', {
            staticClass: "no-control",
            staticStyle: {
              "float": "right",
              "margin-right": "0"
            }
          }, [_vm._v(" " + _vm._s(item.retailPrice ? item.retailPrice : `0.00`) + " ₽ ")])])]);
        }) : [_c('div', {
          staticClass: "center"
        }, [_vm._v("Не найдено")])]], 2) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1)];
      }
    }], null, false, 1897684964)
  })] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }