import { BranchModel } from '@/models/branch.model'
import { StorageModel } from '@/models/storage.model'
import { UserModel } from '@/models/user.model'
import { ManufactureSpecificationModel } from '@/models/manufacture-spec.model'
import { EntityModel } from '@/models/entity.model'

export class ManufactureHeadModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request?.branch)
    this.comment = request?.comment
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.fixed
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.owner = new UserModel(request?.owner)
    this.specifications = request.specifications?.map((el) => new ManufactureSpecificationModel(el)) || []
    this.entity = new EntityModel(request?.entity)
    this.retail_sum = request?.retail_sum
    this.storage = new StorageModel(request?.storage)
    this.targetStorage = new StorageModel(request?.targetStorage)
    this.sum = request?.sum
  }

  setStorage = (val) => {
    this.storage = val
  }

  setEntity = (val) => {
    this.entity = val
  }

  setTargetStorage = (val) => {
    this.targetStorage = val
  }

  setSupplier = (val) => {
    this.supplier = val
  }

  setOperation = (val) => {
    this.operation = val
  }

  setDate = (val) => {
    this.date = new Date(val)
  }

  setComment = (val) => {
    this.comment = val
  }
  get input() {
    return {
      branch: this.branch?.id,
      comment: this.comment,
      date: this.date,
      id: this.id,
      name: this.name,
      entity: this.entity?.id,
      storage: this.storage?.id,
      targetStorage: this.targetStorage?.id
    }
  }
}
